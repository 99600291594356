export default {
  data: () => ({
    pageParams: {
      page: 1,
      onPage: 25
    }
  }),
  methods: {
    pageNext(page, reloadFunctionName) {
      if (page !== this.pageParams.page) {
        this.pageParams.page = page
        this[reloadFunctionName]({})
      }
    }
  }
}