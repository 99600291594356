<template>
  <div class="py-10 py-md-20 px-9 px-md-18">
    <div class="d-flex flex-wrap justify-space-between align-center">
      <div class="d-flex pb-6 pb-md-12 pr-6">
        <v-btn
          class="defaultBg"
          height="48"
          min-width="58"
          depressed
          @click="loadLogs"
        >
          <v-icon color="accentColor">mdi-reload</v-icon>
        </v-btn>
      </div>
    </div>
    
    <v-data-table
      class="defaultBg"
      :headers="headers"
      :items="items"
      :expanded="expanded"
      :loading="loading"
      :custom-sort="() => items"
      loader-height="2"
      mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
      show-expand
      disable-sort
    >
      <template #item.data-table-expand="{ expand, isExpanded, item }">
        <v-icon @click="expand(!isExpanded)">mdi-menu-down</v-icon>
      </template>
      
      <template #expanded-item="{ headers, item }">
        <td class="px-0" :colspan="headers.length">
          <TableLogProperties
            :properties="Array.isArray(item.properties) ? {} : item.properties"
          />
        </td>
      </template>
      
      <template #item.user="{ item }">
        <span class="text-no-wrap">{{ item.causer ? item.causer.email : '-//-' }}</span>
      </template>
      
      <template #item.description="{ item }">
        <span class="text-no-wrap">{{ formatText(item.description) }}</span>
      </template>
      
      <template #item.subjectType="{ item }">
        <span class="text-no-wrap">{{ item['subject_type'] || '-//-' }}</span>
      </template>
      
      <template #item.created="{ item }">
        <span class="text-no-wrap">{{ getTime(item.created_at) }}</span>
      </template>
      
    </v-data-table>
    
    <v-pagination
      class="paginationBody text-center pt-6"
      v-if="items.length && !(dataPagination['last_page'] === 1)"
      :value="dataPagination['current_page']"
      :length="dataPagination['last_page']"
      :page="dataPagination['current_page']"
      total-visible="8"
      :disabled="loading"
      @input="changePage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import changeOnPage from '@/mixins/changeOnPage'
import pageNext from '@/mixins/pageNext'

import TooltipAction from '@/components/tooltip/TooltipAction'
import TableLogProperties from '@/components/tables/TableLogProperties'

export default {
  name: 'Logs',
  components: { TooltipAction, TableLogProperties },
  mixins: [ changeOnPage, pageNext ],
  data: () => ({
    headers: [
      { text: '', value: 'data-table-expand' },
      { text: 'User', value: 'user' },
      { text: 'Description', value: 'description' },
      { text: 'Subject Type', value: 'subjectType' },
      { text: 'Created', value: 'created' },
    ],
    expanded: [],
  }),
  computed: {
    ...mapState({
      items: state => state.logs.items,
      loading: state => state.logs.loading,
      dataPagination: state => state.logs.dataPagination,
    })
  },
  mounted() {
    this.loadLogs()
  },
  methods: {
    ...mapActions({
      getLogs: 'logs/GET_LOGS',
      resetState: 'logs/RESET_STATE',
    }),
    loadLogs() {
      const sortBy = this.serverSorting ? { ...this.serverSorting } : {}
      const params = {
        ...this.pageParams,
        search: this.search ? this.search : undefined,
        ...sortBy
      }
      this.getLogs(params)
    },
    getTime(time) {
      return `${new Date(time).toLocaleDateString()} ${new Date(time).toLocaleTimeString()}`
    },
    changePage(page) {
      if (this.dataPagination['current_page'] !== page) {
        this.expanded = []
      }
      this.pageNext(page, 'loadLogs')
    },
    formatText(text) {
      return text[0].toUpperCase() + text.slice(1)
    }
  },
  beforeDestroy() {
    this.resetState()
  }
}
</script>
