var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-10 py-md-20 px-9 px-md-18"},[_c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center"},[_c('div',{staticClass:"d-flex pb-6 pb-md-12 pr-6"},[_c('v-btn',{staticClass:"defaultBg",attrs:{"height":"48","min-width":"58","depressed":""},on:{"click":_vm.loadLogs}},[_c('v-icon',{attrs:{"color":"accentColor"}},[_vm._v("mdi-reload")])],1)],1)]),_c('v-data-table',{staticClass:"defaultBg",attrs:{"headers":_vm.headers,"items":_vm.items,"expanded":_vm.expanded,"loading":_vm.loading,"custom-sort":function () { return _vm.items; },"loader-height":"2","mobile-breakpoint":"0","hide-default-footer":"","disable-pagination":"","show-expand":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-menu-down")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('TableLogProperties',{attrs:{"properties":Array.isArray(item.properties) ? {} : item.properties}})],1)]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.causer ? item.causer.email : '-//-'))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatText(item.description)))])]}},{key:"item.subjectType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item['subject_type'] || '-//-'))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.getTime(item.created_at)))])]}}])}),(_vm.items.length && !(_vm.dataPagination['last_page'] === 1))?_c('v-pagination',{staticClass:"paginationBody text-center pt-6",attrs:{"value":_vm.dataPagination['current_page'],"length":_vm.dataPagination['last_page'],"page":_vm.dataPagination['current_page'],"total-visible":"8","disabled":_vm.loading},on:{"input":_vm.changePage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }