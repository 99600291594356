<template>
  <v-tooltip
    v-bind="$attrs"
    :max-width="maxWidth"
    :color="color"
    :open-delay="openDelay"
    :z-index="zIndex"
    class="rounded"
  >
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        class="ml-2"
        min-width="32"
        width="32"
        height="32"
        icon
      >
        <slot name="icon"></slot>
      </v-btn>
    </template>
    
    <span class="font-normal primary--text">{{ message }}</span>
  </v-tooltip>
</template>
<script>

export default {
  name: 'TooltipAction',
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'mainBg'
    },
    maxWidth: {
      type: String | Number,
      default: 'auto'
    },
    openDelay: {
      type: String | Number,
      default: 100
    },
    zIndex: {
      type: String | Number,
      default: 5
    }
  }
}
</script>
