<template>
  <v-data-table
    class="subBg rounded-0"
    :headers="headers"
    :items="items"
    loader-height="2"
    mobile-breakpoint="0"
    hide-default-footer
    disable-pagination
    disable-sort
  >
    <template #item.property="{ item }">
      <span class="text-no-wrap">{{ item.property }}</span>
    </template>
  
    <template #item.current="{ item }">
      <span class="truncate" :class="{ 'primary--text': item.differently }">{{ item.current ? item.current : '-//-' }}</span>
    </template>
  
  
    <template #item.old="{ item }">
      <span class="truncate" :class="{ 'error--text': item.differently }">{{ item.old ? item.old : '-//-'}}</span>
    </template>
  
  </v-data-table>
</template>

<script>
export default {
  name: 'TableLogProperties',
  inheritAttrs: false,
  props: {
    properties: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    headers: [
      { text: '', value: 'padding', width: 66 },
      { text: 'Property name', value: 'property', width: 300 },
      { text: 'Current', value: 'current', width: 300 },
      { text: 'Old', value: 'old', width: 300 },
      { text: '', value: 'hidden' },
    ],
    items: [],
  }),
  computed: {},
  mounted() {
    this.parseProps()
  },
  methods: {
    parseProps() {
      if (!Object.keys(this.properties).length) {
        return
      }
      
      if (this.properties.hasOwnProperty('attributes')) {
        Object.keys(this.properties.attributes).forEach(name => {
          const propertyName = this.formatName(name)
          const differently = this.properties?.old
            ? this.properties.attributes[name] !== this.properties.old[name]
            : false
          
          this.items.push({
            property: propertyName,
            current: this.properties.attributes[name],
            old: this.properties?.old ? this.properties.old[name] : '-//-',
            differently
          })
        })
      }
    },
    formatName(name) {
      let newName = name.split('_')
      
      if (newName.length > 1 && newName[newName.length - 1] === 'id') {
        newName.length = newName.length -1
      }

      newName = newName.join(' ')

      newName = newName[0].toUpperCase() + newName.slice(1)
      
      return newName
    },
  }
}
</script>
