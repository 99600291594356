export default {
  data: () => ({
    onPageItems: [10, 25, 50]
  }),
  mounted() {
    this.pageParams.onPage = this.$store.state.pageParams.onPage
  },
  methods: {
    changeOnPage(onPage, reloadFunctionName) {
      this.$store.dispatch('pageParams/GET_ON_PAGE', onPage)
      this.pageParams.page = 1
      this.pageParams.onPage = onPage
      this[reloadFunctionName]({})
    }
  }
}